.back {
  background-image: url('./assets/LowQualityBack.jpg'); /* Lower size background */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 250px;
  border-radius: 10px;
  transition: background-image 0.3s ease-in-out; /* Smooth transition */
}

.back.high-res {
  background-image: url('../public/assets/Rectangle\ 5\ \(2\).svg'); /* Higher size background */
}
.no-scroll {
  overflow: hidden;
}
.force-reflow {
  content: ' ';
  display: block;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

.trustpilot-widget-container .trustpilot-widget * {
  all: initial; /* Reset all styles */
  display: revert; /* Ensure elements are displayed */
}


@media (min-width: 768px) {
  .back{
    height: 365px;
  }
}
@media (min-width: 1280px) {
  .back{
    height: 525px;
  }
}
